<template>
  <v-col class="pa-0 height-100">
    <v-tabs
      show-arrows
      class="tabs-link bb-gray">
      <v-tabs-slider></v-tabs-slider>
      <v-tab :ripple="false" class="px-0 fz-14 fw-600 hover-none" :class="{'mx-4': index !== tabs.length - 1, 'ml-4': index === tabs.length - 1}" v-for="(tab, index) in tabs" :key="index" :to="tab.slug">{{ tab.name }}</v-tab>
    </v-tabs>
    <router-view class="scroll" style="height: calc(100% - 49px)"/>
  </v-col>
</template>

<script>
export default {
  data () {
    return {
      tabs: [
        {
          name: 'Product Plan',
          slug: '/plans/productplan'
        },
        {
          name: 'Additional Services',
          slug: '/plans/additionalservices'
        },
        {
          name: 'Project Rates',
          slug: '/plans/projectrates'
        },
        {
          name: 'Fee & Payment Rates',
          slug: '/plans/paymentsrates'
        },
        {
          name: 'Discounts',
          slug: '/plans/discounts'
        },
        {
          name: 'Management Rates',
          slug: '/plans/managementrates'
        },
        {
          name: 'Company Work Rate',
          slug: '/plans/workrate'
        }
      ]
    }
  }
}
</script>
